<template>
  <span>
    <span
      @click="toggle = true"
      class="text-warning pointer">
      <fa icon="pencil-alt"></fa>
    </span>

    <b-modal v-model="toggle" :title="title">
      <div class="form-row">
        <sgv-input-number
          label="จำนวน"
          class="col-6"
          v-model="formData.qty"
          :precision="2"
          minus
          :validations="[
            {text: 'required!', value: $v.formData.qty.$dirty && $v.formData.qty.$error}
          ]">
        </sgv-input-number>

        <sgv-input-currency
          label="ค่าสูงสุด"
          class="col-6"
          v-model="formData.price"
          :precision="2"
          minus
          :validations="[
            {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
          ]">
        </sgv-input-currency>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <div class="w-100">
          <button
            type="button"
            class="btn btn-danger"
            @dblclick="deleteData">
            ลบ
          </button>

          <button
            type="button"
            class="btn btn-primary float-right"
            @click="cancel()">
            กลับ
          </button>

          <button
            type="button"
            class="btn btn-warning float-right mr-3"
            @click="addData">
            แก้ไข
          </button>
        </div>
      </template>
    </b-modal>

  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        qty: 0,
        price: 0,
        isTag: false
      }
    }
  },
  validations: {
    formData: {
      qty: {required},
      price: {required},
    }
  },
  methods: {
    addData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = {
        options: {
          qty: this.formData.qty,
          price: this.formData.price,
          isTag: false
        }
      }
      this.$emit('added', {profileId: this.docConfigId, input})
      this.toggle = false
    },
    deleteData () {
      this.$emit('deleted', {profileId: this.docConfigId})
      this.toggle = false
    }
  },
  watch: {
    toggle (v) {
      if (v === false) {
        this.$v.formData.$reset()

        this.formData.qty = 0
        this.formData.price = 0
        this.formData.isTag = false
      } else if (v === true && this.docConfigId && this.item) {
        this.formData.qty = this.item.options.qty
        this.formData.price = this.item.options.price
        this.formData.isTag = this.item.options.isTag
      }
    }
  }
}
</script>
