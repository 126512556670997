<template>
  <div class="">
    <div class="form-row">
      <DocConfigFormContact
        placeholder="เลือกพนักงาน..."
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        :types="contactTypes"
        v-model="formData.contactId"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DocConfigFormContact>

      <DocConfigDetailEmployeeFormJobDescription
        label="ตำแหน่งงาน"
        placeholder="เลือกตำแหน่งงาน..."
        :disabled="$auth.disabled(method)"
        class="col-6"
        select="id"
        :docType="docType"
        :templateType="templateType"
        :types="contactTypes"
        v-model="formData.parentId"
        :validations="[
          {text: 'required!', value: v.formData.parentId.$dirty && v.formData.parentId.$error}
        ]">
        <template slot-scope="option">
          {{option.name}}
        </template>
      </DocConfigDetailEmployeeFormJobDescription>

      <sgv-input-text
        label="เลขที่บัญชีผู้รับ"
        :disabled="$auth.disabled(method)"
        v-model="formData.options.paymentName"
        class="col-6"
        :validations="[
          {text: 'required!', value: v.formData.options.paymentName.$dirty && v.formData.options.paymentName.$error}
        ]">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'
import DocConfigDetailEmployeeFormJobDescription from './DocConfigDetailEmployeeFormJobDescription'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    contactTypes: {
      type: Array,
      required: true
    }
  },
  components: {
    DocConfigFormContact,
    DocConfigDetailEmployeeFormJobDescription
  }
}
</script>

<style lang="css">
</style>
