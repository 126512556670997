<template>
  <div class="">
    {{title}}:
    <ul v-if="items.length > 0">
      <li
        v-for="item in itemsMapped"
        :key="item.id">

        <small>
          <div class="text-warning">
            {{item.inventory.code}} ({{item.inventory.name}})
            <span v-if="item.options.isTag">
              <fa icon="random" class="text-success"></fa>
            </span>

            <DocConfigDetailEmployeeProfileModal
              class="ml-1"
              :title="title"
              :profileType="profileType"
              :docType="docType"
              :templateType="templateType"
              :method="method"
              :docConfigId="item.id"
              :item="item"
              @added="addProfile"
              @deleted="deleteProfile">
            </DocConfigDetailEmployeeProfileModal>
          </div>

          <div class="text-info">
            บัญชี: {{item.account.code}} ({{item.account.name}})
          </div>
          <div class="">
            จำนวน:
            <span :class="{
              'text-success': item.options.qty > 0,
              'text-danger': item.options.qty < 0,
            }">
              {{item.options.qty}} {{item.inventory.unit.name}}
            </span>

            ราคา:
            <span :class="{
              'text-success': item.options.qty > 0,
              'text-danger': item.options.qty < 0,
            }">
              {{item.options.price}} บาท
            </span>

            <span
              v-if="!item.isOverwrite"
              class="text-success">
              --- ค่าเริ่มต้น
            </span>
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import DocConfigDetailEmployeeProfileModal from './DocConfigDetailEmployeeProfileModal'
import {
  LIST_DOC_CONFIG_EMPLOYEE_PROFILE,
  LIST_DOC_CONFIG_EMPLOYEE_PROFILE_OVERWRITE,
  CREATE_DOC_CONFIG_EMPLOYEE_PROFILE,
  DESTROY_DOC_CONFIG_EMPLOYEE_PROFILE,
} from './graph'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    profileType: {
      type: String,
      required: true
    },
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      junctions: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_EMPLOYEE_PROFILE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId,
          types: [this.profileType],
        }
      },
      fetchPolicy: 'no-cache',
    },
    junctions: {
      query () {
        return LIST_DOC_CONFIG_EMPLOYEE_PROFILE_OVERWRITE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId,
          types: [this.profileType],
        }
      },
      fetchPolicy: 'no-cache',
    }
  },
  computed: {
    itemsMapped () {
      return this.items.map(v => {
        const ow = this.getOverwrite(v.id)

        return {
          id: v.id,
          type: v.type,
          docType: v.docType,
          accountId: v.accountId,
          account: v.account,
          inventoryId: v.inventoryId,
          inventory: v.inventory,
          options: ow ? ow.options : v.options,
          isOverwrite: ow ? true : false
        }
      })
    }
  },
  methods: {
    getOverwrite (profileId) {
      return this.junctions.find(v => v.childId === profileId)
    },
    addProfile ({profileId, input}) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_EMPLOYEE_PROFILE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          profileId,
          input
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$apollo.queries.junctions.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteProfile ({profileId}) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_EMPLOYEE_PROFILE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          profileId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$apollo.queries.junctions.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    refetch () {
      this.$apollo.queries.junctions.refetch()
      this.$apollo.queries.items.refetch()
    }
  },
  components: {
    DocConfigDetailEmployeeProfileModal
  }
}
</script>

<style lang="css">
</style>
