<template>
  <div class="">
    ค่าแรงค้างจ่าย:
    <ul>
      <li
        v-for="item in items"
        :key="item.id">
        {{item.child.name}}
        <small>
          <div v-if="item.child.inventory" class="text-warning">
            {{item.child.inventory.code}} ({{item.child.inventory.name}})
          </div>
          <div v-if="item.child.account" class="text-info">
            บัญชี: {{item.child.account.code}} ({{item.child.account.name}})
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import { LIST_DOC_CONFIG_EMPLOYEE_NET_PRICE } from './graph'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_EMPLOYEE_NET_PRICE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId
        }
      },
      fetchPolicy: 'no-cache',
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.items.refetch()
    }
  }
}
</script>

<style lang="css">
</style>
