<template>
  <div v-if="isLoaded">
    <sgv-form
      :method.sync="method"
      :options="options">

      <DocConfigDetailEmployeeForm
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :formData="formData"
        :method="method"
        :v="$v"
        :contactTypes="contactTypes">
      </DocConfigDetailEmployeeForm>
    </sgv-form>

    <template v-if="docConfigId > 0">
      <hr-divider :options="[{text: 'ทั่วไป'}]"></hr-divider>

      <DocConfigDetailEmployeeNetPrice
        ref="netPrice"
        v-if="docConfigId > 0"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        @update="dataDetail">
      </DocConfigDetailEmployeeNetPrice>

      <DocConfigDetailEmployeeSocialSecurity
        ref="socialSecurity"
        v-if="docConfigId > 0"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        @update="dataDetail">
      </DocConfigDetailEmployeeSocialSecurity>

      <DocConfigDetailEmployeeTax
        ref="tax"
        v-if="docConfigId > 0"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        @update="dataDetail">
      </DocConfigDetailEmployeeTax>

      <hr-divider :options="[{text: 'หักค่าใช้จ่าย'}]"></hr-divider>

      <DocConfigDetailEmployeeDiscount
        ref="discount"
        v-if="docConfigId > 0"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        :parentId="formData.parentId"
        @update="dataDetail">
      </DocConfigDetailEmployeeDiscount>

      <hr-divider :options="[{text: 'รายได้'}]"></hr-divider>

      <DocConfigDetailEmployeeProfile
        :ref="`profile-${incomeType.value}`"
        class="mb-3"
        v-for="incomeType in incomeTypes"
        :key="incomeType.value"
        :title="incomeType.text"
        :profileType="incomeType.value"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        @update="dataDetail">
      </DocConfigDetailEmployeeProfile>

      <hr-divider :options="[{text: 'รายการหัก'}]"></hr-divider>

      <DocConfigDetailEmployeeProfile
        :ref="`profile-${defectType.value}`"
        class="mb-3"
        v-for="defectType in defectTypes"
        :key="defectType.value"
        :title="defectType.text"
        :profileType="defectType.value"
        :docConfigId="docConfigId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        @update="dataDetail">
      </DocConfigDetailEmployeeProfile>
    </template>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DocConfigDetailEmployeeForm from './DocConfigDetailEmployeeForm'
import DocConfigDetailEmployeeNetPrice from './DocConfigDetailEmployeeNetPrice'
import DocConfigDetailEmployeeSocialSecurity from './DocConfigDetailEmployeeSocialSecurity'
import DocConfigDetailEmployeeTax from './DocConfigDetailEmployeeTax'
import DocConfigDetailEmployeeDiscount from './DocConfigDetailEmployeeDiscount'
import DocConfigDetailEmployeeProfile from './DocConfigDetailEmployeeProfile'
import {
  DETAIL_DOC_CONFIG_EMPLOYEE,
  CREATE_DOC_CONFIG_EMPLOYEE,
  DESTROY_DOC_CONFIG_EMPLOYEE,
} from './graph'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.title
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}DocConfigListEmployee`,
      formData: {
        parentId: null,
        contactId: null,
        contact: null,
        options: {
          paymentName: ''
        },
        children: []
      },
      incomeTypes: [
        {text: 'เงินเดือน', value: 'salary'},
        {text: 'เงินล่วงเวลา', value: 'ot'},
        {text: 'เงินได้หน้าที่', value: 'duty'},
        {text: 'สวัสดิการ', value: 'welfare'},
        {text: 'เงินรางวัล', value: 'premium'},
        {text: 'เงินได้อื่นๆ', value: 'otherIncome'},
      ],
      defectTypes: [
        {text: 'หักบกพร่อง', value: 'defectDuty'},
        {text: 'หักสวัสดิการ', value: 'defectWelfare'},
        {text: 'หักกยศ', value: 'studentLoan'},
        {text: 'หักอื่นๆ', value: 'defectIncome'},
      ],
      isLoaded: false,
    }
  },
  validations: {
    formData: {
      parentId: {required},
      contactId: {required},
      options: {
        paymentName: {required}
      }
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:config`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC_CONFIG_EMPLOYEE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.item)
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'options') {
          this.formData.options.paymentName = v.options?.paymentName
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      return {
        parentId: v.parentId,
        contactId: v.contactId,
        options: v.options
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_EMPLOYEE(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.item.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_EMPLOYEE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.refetch()
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_EMPLOYEE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
    refetch () {
      Object.keys(this.$refs).forEach(p => {
        if (Array.isArray(this.$refs[p])) {
          this.$refs[p][0].refetch()
        } else {
          this.$refs[p].refetch()
        }
      })
    }
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
  },
  components: {
    DocConfigDetailEmployeeForm,
    DocConfigDetailEmployeeNetPrice,
    DocConfigDetailEmployeeSocialSecurity,
    DocConfigDetailEmployeeTax,
    DocConfigDetailEmployeeDiscount,
    DocConfigDetailEmployeeProfile,
  }
}
</script>

<style lang="css">
</style>
