<template>
  <div class="">
    หักค่าใช้จ่าย:
    <ul v-if="discounts.length > 0">
      <li
        v-for="match in matches"
        :key="match.id">
        <span
          v-if="method === 'edit' && !selected.includes(match.id) && !isJobDescription(match.id)"
          class="text-success pointer"
          @click="addDiscount(match.id)">
          <fa icon="check"></fa>
        </span>
        <span
          v-if="method === 'edit' && selected.includes(match.id) && !isJobDescription(match.id)"
          class="text-danger pointer"
          @click="deleteDiscount(match.id)">
          <fa icon="trash"></fa>
        </span>

        <span>
          {{match.name}}: {{match.options.value}}
          <span v-if="match.options.value == 0" class="text-info">
            <fa icon="arrow-right"></fa>
            {{getAlternativeValue(match.id)}}
          </span>
          <span v-if="match.options.maxValue" class="text-danger">
            ({{match.options.maxValue}})
          </span>
          <DocConfigDetailEmployeeDiscountModal
            class="ml-1"
            v-if="match.options.value == 0 && selected.includes(match.id)"
            :title="match.name"
            :parentId="docConfigId"
            :discountId="match.id"
            @update="updateDiscount">
          </DocConfigDetailEmployeeDiscountModal>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import DocConfigDetailEmployeeDiscountModal from './DocConfigDetailEmployeeDiscountModal'
import {
  LIST_DOC_CONFIG_DISCOUNT,
  LIST_DOC_CONFIG_EMPLOYEE_DISCOUNT,
  CREATE_DOC_CONFIG_EMPLOYEE_DISCOUNT,
  UPDATE_DOC_CONFIG_EMPLOYEE_DISCOUNT,
  DESTROY_DOC_CONFIG_EMPLOYEE_DISCOUNT,
} from './graph'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      discounts: [],
      items: []
    }
  },
  apollo: {
    discounts: {
      query () {
        return LIST_DOC_CONFIG_DISCOUNT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            limit: 100
          }
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.items
    },
    items: {
      query () {
        return LIST_DOC_CONFIG_EMPLOYEE_DISCOUNT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    selected () {
      return this.items.map(v => v.child.id)
    },
    matches () {
      if (['edit', 'add'].includes(this.method)) {
        return this.discounts
      } else {
        return this.discounts.filter(v => this.selected.includes(v.id))
      }
    }
  },
  methods: {
    isJobDescription (childId) {
      const item = this.items.find(v => v.childId === childId)
      return item?.parentId === this.parentId
    },
    getAlternativeValue (childId) {
      const item = this.items.find(v => v.childId === childId)
      return item?.options?.value || 0
    },
    addDiscount (discountId) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_EMPLOYEE_DISCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          discountId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateDiscount ({parentId, discountId, input}) {
      this.$apollo.mutate({
        mutation: UPDATE_DOC_CONFIG_EMPLOYEE_DISCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          parentId,
          discountId,
          input
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteDiscount (discountId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_EMPLOYEE_DISCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          discountId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    refetch () {
      this.$apollo.queries.discounts.refetch()
      this.$apollo.queries.items.refetch()
    }
  },
  components: {
    DocConfigDetailEmployeeDiscountModal
  }
}
</script>

<style lang="css">
</style>
